import React, { useState } from 'react';
import './App.css';
import { SocialIcon } from 'react-social-icons';

function Footer() {
  return (
    <span>
      <section class="social-media-icons">
        <SocialIcon target={"_blank"} url="mailto:shehabattia96@gmail.com" title="email"/>
        <SocialIcon target={"_blank"} url="https://www.linkedin.com/in/shehabattia/" title="LinkedIn"/>
      </section>
    </span>
  );
}

function Landing() {

  return (
    <div className="Page" id="home">
      <header className="App-header">

        <h1>
          Shehab Attia
        </h1>
        <section>
          Aspiring Roboticist!
        </section>

        <section>
          Website Work-in-Progress...<span className='blink'>|</span>
        </section>
        <section>Visit again soon!</section>

        {Footer()}
      </header>
    </div>
  );

}


function App() {
  return (
    <div>
      {Landing()}
    </div>
  );
}

export default App;
